@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialThin.otf") format("opentype");
  font-weight: 100; /* Thin */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialThinItalic.otf") format("opentype");
  font-weight: 100; /* Thin */
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialLight.otf") format("opentype");
  font-weight: 300; /* Light */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialLightItalic.otf") format("opentype");
  font-weight: 300; /* Light */
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialRegular.otf") format("opentype");
  font-weight: 400; /* Regular */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialDemibold.otf") format("opentype");
  font-weight: 600; /* Demibold */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialDemiboldItalic.otf") format("opentype");
  font-weight: 600; /* Demibold */
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialItalic.otf") format("opentype");
  font-weight: 400; /* Regular */
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialHair.otf") format("opentype");
  font-weight: 800; /* Hair */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mundial";
  src: url("../public/fonts/MundialHairItalic.otf") format("opentype");
  font-weight: 800; /* Hair */
  font-style: italic;
  font-display: swap;
}
